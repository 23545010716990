import { OngoingOrderDto } from './ongoing-order.dto';

export class OngoingOrder {
    orderDate: Date | null;
    orderNo: string;
    encodedOrderNo: string;
    revNo: string;
    totalAmountIncludingTax: number;
    totalAmountExcludingTax: number;
    requestedDeliveryDate: Date | null;
    plannedDeliveryDate: Date | null;
    confirmationStatus: string;
    deliveryStatus: string;

    constructor(dto: OngoingOrderDto) {
        this.orderDate = dto.order_date ? new Date(dto.order_date) : null;
        this.orderNo = dto.number ?? '';
        this.encodedOrderNo = encodeURIComponent(dto.number ?? '');
        this.revNo = dto.rev_no ?? '';
        this.totalAmountIncludingTax = dto.total_amount_including_tax ? Math.round(100 * dto.total_amount_including_tax) : 0;
        this.totalAmountExcludingTax = dto.total_amount_excluding_tax ? Math.round(100 * dto.total_amount_excluding_tax) : 0;
        this.requestedDeliveryDate =
            dto.requested_delivery_date_ts && dto.requested_delivery_date_ts > 0 ? new Date(dto.requested_delivery_date_ts * 1000) : null;
        this.plannedDeliveryDate = dto.planned_delivery_date_ts && dto.planned_delivery_date_ts > 0 ? new Date(dto.planned_delivery_date_ts * 1000) : null;
        this.confirmationStatus = dto.confirmation_status ?? '';
        this.deliveryStatus = dto.delivery_status ?? '';
    }
}
